<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">直播参数</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel" style="width:95px; font-size:14px; padding:0 0.5rem">培训类型:</span>
              <el-cascader
                    size="small"
                    :props="{emitPath:false,value:'id',label:'label',children:'children',checkStrictly: true}"
                    v-model="trainTypeId"
                    :options="trainTypeList"
                    clearable
                  ></el-cascader>
            </span>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="addclassSubject()">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="行政区划"
                align="center"
                show-overflow-tooltip
                prop="areaNamePath"
              />
              <el-table-column
                label="培训类型"
                align="center"
                show-overflow-tooltip
                prop="trainTypeNamePath"
              >
                <template slot-scope="scope">{{scope.row.trainTypeNamePath || '--'}}</template>
              </el-table-column>
              <el-table-column label="操作" align="center" show-overflow-tooltip>
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleEdit(scope.row.paraId)"
                  >编辑</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/classParams",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      areatreeList: [], // 行政区划
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      areaId: "",
      trainTypeId:'',
      trainTypeList:[]
    };
  },
  created() {},
  mounted() {
    this.getTableHeight();
    this.getareatree();
    this.getTraintype();
  },
  methods: {
       getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
        // projectName: this.projectName || "",
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      this.doFetch({
        url: "/parameter/live-parameter/page",
        params,
        pageNum
      },true,3);
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then(res => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    addclassSubject() {
      this.$router.push({
        path: "/web/operate/liveBroadcastParametersEdit"
      });
    },
    handleEdit(paraId) {
      this.$router.push({
        path: "/web/operate/liveBroadcastParametersEdit",
        query: {
          paraId,
          stu: "edit"
        }
      });
    }
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less" scope>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
